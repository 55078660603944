:root {
  --black: #030303;
  --white: #FFFFFF;
  --dark-navy: #15113E;
  --light-blue: #51B7A9;
  --gray-bg: #D7D6E3;
  --medium-gray: #a0a0a3;
  --dark-gray: #69696e;
  --violet-bg: #293085;
  --dark-violet: #3B3764;
  --dark-red: #AC0707;
  --dark-green: #0B6E4F;
  --green: #92B751;
  --coral: #B7515F;
}

/*-----------------------------------------------------------------------------*/

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

/*-----------------------------------------------------------------------------*/

body {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* min-width: 768px; */
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Hanken Grotesk", sans-serif;
  color: var(--black);
}

img {
  max-width: 100%;
  height: auto;
}

#root {
  height: 100%;
}

/*! .wrapper лежит в Layout.module.css */

/* @media(max-width: 760px) {
  body {
    font-size: 16px;
  }
} */

@media(max-width: 767px) {
  body._lock {
    overflow: hidden;
  }
}