.navigation {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 180px;
  color: var(--dark-navy);
  font-size: 16px;
  margin-top: 25px;
}

.navigation__title {
  /* font-size: 14px; */
  font-weight: 600;
  text-transform: uppercase;
  /* z-index: 20; */
}

.navigation__links {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.navigation__main {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Specific styles for non-active links */
.link {
  color: var(--dark-navy);

  transition: all 0.3s ease 0s;
}

.link:hover {
  color: var(--light-blue);
}

/* Specific styles for active links */
.link-active {
  color: var(--light-blue);
}

.button-link {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--violet-bg);
  color: var(--white);

  transition: all 0.3s ease 0s;
}

.button-link:hover {
  color: var(--white);
  background-color: var(--light-blue);
}

.button-hidden {
  visibility: hidden;
  width: 120px;
}

.menu__button {
  display: none;
}

.menu__logo {
  display: none;
}

/** ------------------------------------------------------ */

@media(max-width: 767px) {
  .menu__button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    min-height: 56px;
    min-width: 56px;
    border-radius: 50%;
    background-color: var(--violet-bg);
  }

  /* .menu__button._active {} */

  .menu__icon {
    display: block;
    position: relative;
    width: 30px;
    height: 22px;
    cursor: pointer;
    z-index: 20;
    transition: all 0.5s ease 0s;
  }

  .menu__icon::before,
  .menu__icon::after {
    content: "";
  }

  .menu__icon::before,
  .menu__icon::after,
  .menu__icon span {
    right: 0px;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: var(--white);
    /* background-color: var(--dark-navy); */
    transition: all 0.5s ease 0s;
  }

  .menu__icon::before {
    top: 0px;
  }

  .menu__icon::after {
    bottom: 0px;
  }

  .menu__icon span {
    top: 50%;
    transform: scale(1) translate(0px, -50%);
  }

  .menu__icon._active {
    display: block;
    position: relative;
    min-width: 30px;
    height: 22px;
    cursor: pointer;
    z-index: 20;
    transition: all 0.5s ease 0s;

    transform: rotate(-90deg);
  }

  .navigation__links {
    flex-direction: column;

    position: fixed;
    top: 0;
    right: -100%;
    /* max-width: 320px; */
    width: 100%;
    height: 100%;
    /* background-color: #f1f0fb; */
    background-color: var(--white);
    transition: right 0.5s linear 0s;
    overflow: auto;

    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }

  .navigation__links._active {
    right: 0;
  }

  /* .navigation__links._active::after {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #454444;
    z-index: -1;
    opacity: 0.5;
  } */

  .navigation__main {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;

    font-size: 20px;
    font-weight: 500;
  }

  .button-link {
    width: 100%;
    font-size: 20px;
  }

  .menu__logo {
    display: block;
    /* width: 320px; */
    padding-top: 0;
    /* background-color: #292929; */
    position: absolute;
    top: -500%;
    right: 0;
    opacity: 0;
  }

  .menu__logo._active {
    top: 38px;
    left: 15px;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.7s ease 0.7s;
  }
}


/* .blur {
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 320px;
  backdrop-filter: blur(2px);
  position: absolute;
} */