.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  /* vertical-align: middle; */
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}

.w-input:focus,
.w-select:focus {
  /* border-color: #3898EC; */
  outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
  background-color: #eeeeee;
}

textarea.w-input,
textarea.w-select {
  height: auto;
}

.w-select {
  background-color: #f3f3f3;
}

.w-select[multiple] {
  height: auto;
}

/** ------------------------------------------------------------------ */

/** remove arrows from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** ------------------------------------------------------------------ */

.form-text-field {
  height: 60px;
  /* min-width: 270px; */
  padding: 0px 20px;
  border-style: solid;
  border-width: 1.5px;
  border-color: rgba(199, 200, 213, 0.52);
  border-radius: 5px;
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
  /* font-family: Apercu, sans-serif; */
  color: var(--violet-bg);
  font-size: 16px;
}

.form-text-field:active {
  border-width: 2px;
  border-color: var(--violet-bg);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

.form-text-field:focus {
  border-width: 2px;
  border-color: var(--violet-bg);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

.form-text-field.error {
  border-width: 2px;
  border-color: var(--dark-red);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

.form-text-field::-webkit-input-placeholder {
  color: rgba(110, 111, 119, 0.78);
}

.form-text-field::-ms-input-placeholder {
  color: rgba(110, 111, 119, 0.78);
}

.form-text-field::placeholder {
  color: rgba(110, 111, 119, 0.78);
}

textarea.form-text-field {
  padding: 10px 20px 10px 20px;
}

.phone-number-field {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
}

/* .optional-field {
  border-color: var(--dark-green);
} */

.optional-field:active,
.optional-field:focus {
  border-color: var(--medium-gray);
}

.optional-field.error {
  border-width: 2px;
  border-color: var(--dark-red);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

/** ------------------------------------------------------------------ */

.dropdown-field {
  height: 60px;
  min-width: 270px;
  padding: 0px 40px 0px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1.5px;
  border-color: rgba(199, 200, 213, 0.52);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
  font: inherit;
  letter-spacing: inherit;
  /* font-family: Apercu, sans-serif; */
  /* color: rgba(110, 111, 119, 0.78); */
  color: var(--violet-bg);
  font-size: 16px;
  -o-object-fit: scale-down;
  object-fit: scale-down;

  /* ниже убираю стандартную стрелку и вставляю свою */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  background-image: url('./down-arrow-5-svgrepo-com.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right 10px center;
}

.dropdown-field:active {
  border-width: 2px;
  border-color: var(--violet-bg);
  color: var(--violet-bg);
}

.dropdown-field:focus {
  border-width: 2px;
  border-color: var(--violet-bg);
  color: var(--violet-bg);
}

.dropdown-field.error {
  border-width: 2px;
  border-color: var(--dark-red);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

.dropdown-field.optional-field:active,
.dropdown-field.optional-field:focus {
  border-color: var(--medium-gray);
}

/* скроет иконку стрелки в IE */
select::-ms-expand {
  display: none;
}

/** ------------------------------------------------------------------ */

.form {
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0 10px;
}

/* .form-modal {
  overflow-y: auto;
} */

.grid-container-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  margin-bottom: 20px;
}

.grid-container-modal-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  margin-bottom: 20px;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  margin-bottom: 20px;
}

.appearing-field {
  /* border-left: 5px solid var(--violet-bg); */
  border-left: 5px solid var(--light-blue);

  padding-left: 20px;
  margin-left: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.grid-item-wide {
  grid-column: 1/3;
}

.grid-item-wide-right {
  grid-column: 2/4;
}

.grid-item-wide-3 {
  grid-column: 1/4;
}

.grid-item-wide-3-with-link {
  grid-column: 1/4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.box-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.box-row-10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.box-row-near {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.box-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.row-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  margin-bottom: 10px;
}

/** ------------------------------------------------------------------ */

.specific-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  /* background-color: var(--light-blue); */
  border: 2px solid var(--light-blue);
  border-radius: 5px;
  font-size: 16px;
  color: var(--dark-violet);

  padding: 20px;
  margin-bottom: 10px;
}

.specific-section.error {
  border: 2px solid var(--dark-red);
}

.specific-section-title {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.specific-section-strong {
  font-weight: 700;
}

.specific-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/** ------------------------------------------------------------------ */

.title {
  color: var(--violet-bg);
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;

  margin-bottom: 30px;
}

.subtitle-tiv {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 500;
  color: var(--dark-violet);
}

.label-checkbox {
  color: var(--violet-bg);
  font-size: 16px;
}

.input-checkbox {
  width: 25px;
  height: 25px;
  min-width: 25px;
  border-width: 1.5px;
  border-color: rgba(199, 200, 213, 0.52);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

/** ------------------------------------------------------------------ */

.half-page-select {
  max-width: calc(50% - 10px);
  flex-grow: 1;
}

.one-third-page-select {
  min-width: calc(33% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
}

.two-third-page-select {
  max-width: calc(66% - 10px);
  flex-grow: 1;
}

.form-field-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* font-family: Apercu, sans-serif; */
  color: var(--dark-violet);
  font-size: 16px;
  font-weight: 400;
}

/** ------------------------------------------------------------------ */

.box-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.box-row-end-button {
  display: flex;
  justify-content: flex-end;
}

/** ------------------------------------------------------------------ */

hr {
  /* Убираем границу */
  border: none;
  /* Цвет линии */
  background-color: var(--violet-bg);
  /* Цвет линии для IE6-7 */
  color: var(--violet-bg);
  /* Толщина линии */
  height: 1px;
}

.map-link {
  text-transform: uppercase;
  text-decoration: underline;
  /* margin-left: 20px; */
}

/** ------------------------------------------------------------------ */

.step-button {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--gray-bg);
  color: var(--violet-bg);
  /* background-color: var(--violet-bg);
  color: var(--white); */
  font-size: 16px;

  transition: all 0.3s ease 0s;
}

.step-button:hover {
  background-color: var(--violet-bg);
  color: var(--white);
  /* color: var(--white);
  background-color: var(--light-blue); */
}

/** ------------------------------------------------------------------ */

.next-step-button {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 4px;
  background-color: var(--gray-bg);
  color: var(--violet-bg);
  /* background-color: var(--violet-bg);
  color: var(--white); */
  font-size: 16px;

  transition: all 0.3s ease 0s;
}

.next-step-button:hover {
  background-color: var(--violet-bg);
  color: var(--white);
  /* color: var(--white);
  background-color: var(--light-blue); */
}

/** ------------------------------------------------------------------ */

.add-button {
  height: 60px;
  min-width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--violet-bg);
  color: var(--white);
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;

  transition: all 0.3s ease-in-out 0s;
}

.add-button:hover {
  background-color: var(--light-blue);
  color: var(--white);
}

.add-button:disabled {
  background-color: var(--gray-bg);
  color: var(--light-blue);
  cursor: default;
}

.add-button.error-button {
  border: 2px solid var(--dark-red);
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.13);
}

/** ------------------------------------------------------------------ */

.create-button {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-green);
  background-color: transparent;
  color: var(--dark-green);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.create-button:hover {
  background-color: var(--dark-green);
  color: var(--white);
}

/** ------------------------------------------------------------------ */

.cancel-button {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-red);
  background-color: transparent;
  color: var(--dark-red);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.cancel-button:hover {
  background-color: var(--dark-red);
  color: var(--white);
}

/** -------------------- QUOTE (FormStep5) ---------------------------------- */

.quote-wrapper {
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0 10px;
}

.quote-container {
  max-width: 70%;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;

  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
  border-radius: 5px;

  padding: 25px 30px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.flex-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.quote-text {
  color: var(--dark-violet);
}

.quote-text-strong {
  color: var(--dark-navy);
  font-weight: 700;
}

.quote-subtitle {
  color: var(--dark-violet);
  font-size: 20px;
  font-weight: 700;
}

/** ------------------------------------------------------------------ */

.sign-wrapper {
  max-width: max-content;
  margin: 0 auto;

  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0 10px;
}

.sign-container {
  /* max-width: 70%; */
  /* min-width: fit-content; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
  border-radius: 5px;

  padding: 25px 30px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.sign-overview {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.sign-flex-item {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  padding: 5px;

  transition: all 0.3s ease 0s;
}

.sign-flex-item:hover {
  background-color: var(--gray-bg);
}

.sign-title {
  /* align-self: flex-start; */
  color: var(--light-blue);
  font-size: 30px;
  font-weight: 700;
}

.sign-subtitle {
  /* align-self: flex-start; */
  text-align: center;
  color: var(--dark-navy);
  font-size: 18px;
  font-weight: 400;

  padding-bottom: 20px;
}

.sign-text {
  color: var(--dark-violet);
}

.sign-text-strong {
  /* color: var(--dark-navy); */
  color: var(--dark-violet);
  font-weight: 700;
}

.sign-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding-top: 20px;
}

.sign-preview {
  max-width: 400px;
  max-height: 80px;
  aspect-ratio: 5/1;
  border: 2px solid var(--violet-bg);
  border-radius: 5px;
}

.sign-image {
  max-width: 100%;
}

/* .sign-image {
  display: block;
  width: 907px;
  height: 165px;
  margin: 0 auto;
  border: 1px solid black;
  padding: 10px;
} */

.sign-apply-button {
  max-width: fit-content;
  height: 50px;
  /* width: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-green);
  background-color: transparent;
  color: var(--dark-green);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.sign-apply-button:hover {
  background-color: var(--dark-green);
  color: var(--white);
}

/** ------------------------------------------------------------------ */

.ok-checkbox {
  /* visibility: hidden; */
  opacity: 0;
}

/** -------------------- @media ---------------------------------- */

@media(max-width: 1000px) {
  .grid-container-modal-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

}

@media(max-width: 600px) {
  .sign-overview {
    align-self: flex-start;
  }

  .sign-flex-item {
    align-self: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}