.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 1300px;
  padding: 0px 15px;
  margin: 0px auto;
}

.box {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-top: 60px;
}

.message {
  color: var(--dark-red);
}

.btns-wrapper {
  align-self: center;

  display: flex;
  gap: 20px;
}

.button {
  height: 50px;
  /* width: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-green);
  background-color: transparent;
  color: var(--dark-green);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.button:hover {
  background-color: var(--dark-green);
  color: var(--white);
}