.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;

  font-size: 16px;
  color: var(--dark-navy);
  /* background-color: #E9F6F4; */
  /* border: 1.5px solid rgba(199, 200, 213, 0.52); */
  border: 1.5px solid var(--dark-navy);
  border-radius: 5px;
  /* box-shadow: 0 4px 13px 1px var(--dark-navy); */
  padding: 10px 20px;

  /* cursor: pointer; */
}

.card:hover {
  box-shadow: 0 4px 13px 1px rgba(41, 48, 133, 0.11);
}

.card-title {
  font-weight: 700;
}

.icons-wrapper {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.edit-icon,
.delete-icon {
  color: var(--violet-bg);

  padding: 5px;
  cursor: pointer;

  transition: color 0.3s ease-in-out 0s;
}

.edit-icon:hover,
.delete-icon:hover {
  color: var(--light-blue);
}