.page-container {
  max-width: 1100px;
  margin: 0 auto;

  margin-top: 80px;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.title {
  color: var(--light-blue);
  font-size: 50px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
  /* text-decoration: underline;
  text-decoration-color: var(--violet-bg); */

  margin-bottom: 50px;
}

.subtitle {
  color: var(--violet-bg);
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
}

.page-container p {
  color: var(--black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.main-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

.main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.main-image {
  min-width: 400px;
}