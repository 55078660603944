.list-container {
  grid-column: 1/4;

  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  margin-bottom: 30px;
}