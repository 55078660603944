.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkedStreet {
  font-weight: 700;
}

.sign-apply-button {
  max-width: fit-content;
  height: 50px;
  /* width: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-green);
  background-color: transparent;
  color: var(--dark-green);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.sign-apply-button:hover {
  background-color: var(--dark-green);
  color: var(--white);
}