/* .sigContainer {} */

.sigCanvas {
  /* max-width: 100%; */
  width: 907px;
  height: 165px;
  aspect-ratio: 5/1;
  border: 2px solid var(--violet-bg);
  border-radius: 5px;
}

.sigButtons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding-top: 20px;
}

/** ------------------------------------------------------------------ */

.sigButton-save {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-green);
  background-color: transparent;
  color: var(--dark-green);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.sigButton-save:hover {
  background-color: var(--dark-green);
  color: var(--white);
}

/** ------------------------------------------------------------------ */

.sigButton-clear {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--dark-red);
  background-color: transparent;
  color: var(--dark-red);
  font-size: 16px;
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.sigButton-clear:hover {
  background-color: var(--dark-red);
  color: var(--white);
}

/** ------------------------------------------------------------------ */

@media(max-width: 1000px) {
  .sigCanvas {
    width: 600px;
    height: 110px;
  }
}

@media(max-width: 767px) {
  .sigCanvas {
    width: 500px;
    height: 91px;
  }
}

@media(max-width: 600px) {
  .sigCanvas {
    width: 400px;
    height: 73px;
  }
}

@media(max-width: 500px) {
  .sigCanvas {
    width: 300px;
    height: 55px;
  }
}