.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  /* padding-top: 20px; */
  padding-bottom: 20px;
}

.scale-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.scale-btn {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 5px;
}

.page-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  color: var(--dark-violet);
}

.page-btn {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--dark-violet);
  border: 2px solid var(--dark-violet);
  /* border-radius: 5px; */
  padding: 10px;

  transition: all 0.3s ease 0s;
}

.page-btn:hover {
  background-color: var(--dark-violet);
  color: var(--white);
}

.page-counter {
  font-size: 14px;
  font-weight: 500;
  /* font-weight: 700; */
  text-transform: uppercase;
}

.document {
  width: 100%;
  height: 400px;
  overflow: auto;

  border: 2px solid var(--violet-bg);
  border-radius: 5px;
}

.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** ------------------------------------------------------------------ */

@media(max-width: 500px) {
  .header {
    flex-direction: column-reverse;
  }
}