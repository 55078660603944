.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dark-violet);
    opacity: 0.5;
}

.modal__main {
    width: 80%;
    height: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    overflow-y: auto;

    font-size: 16px;
    border-radius: 5px;
    background-color: var(--white);
    padding: 40px;
}

.title {
    color: var(--violet-bg);
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;

    margin-bottom: 30px;
}