.backdrop-dark {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.backdrop-light {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0);
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--dark-violet);
  animation: spin 1.7s linear infinite;
  z-index: 11;
}

.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--light-blue);
  animation: spin-reverse .6s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--violet-bg);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(-360deg);
  }
}

.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}