.quote-progress-container {
  /* max-width: 1200px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  /* margin-top: 60px; */
  padding-top: 60px;
}

.progress-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--gray-bg);
}

.progress-icon i {
  color: var(--violet-bg);
}

.progress-icon.active {
  background-color: var(--violet-bg);
}

.progress-icon.active i {
  color: var(--white);
}

.progress-step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.progress-step-label {
  display: flex;
  /* margin-left: 20px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* flex: 0 0 auto; */
}

.subline {
  /* font-family: Apercu, sans-serif; */
  /* color: #293085; */
  font-weight: 700;
  letter-spacing: 1px;
}

.subline.margin-5 {
  margin-bottom: 5px;
  /* color: rgba(21, 17, 62, 0.7); */
  color: var(--dark-navy);
  font-size: 13px;
}

.heading-5 {
  margin-top: 0px;
  margin-bottom: 0px;
  /* font-family: Apercu, sans-serif; */
  color: var(--dark-navy);
  font-size: 18px;
  font-weight: 400;
}